<template>
  <div class="app-container stadium-site-calendar">
    <div class="filter-line">
      <el-select
        class="project-selector"
        v-model="projectId"
        placeholder="请选择项目"
        clearable
        @change="getTableList"
      >
        <el-option
          v-for="item in projectList"
          :value="item.projectId"
          :label="item.projectName"
          :key="item.projectId"
        ></el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-plus" @click="handleFix"
        >预定场地</el-button
      >
      <el-button icon="el-icon-refresh-right" @click="getTableList">{{
        this.$t("commons.refresh")
      }}</el-button>
    </div>
    <el-divider></el-divider>
    <div class="calendar-contant">
      <!-- 头部 -->
      <div class="contantTitle">
        <p>
          <i
            class="el-icon-s-order"
            style="font-size: 20px; color: #1a4cec; margin-right: 3px"
          ></i>
          <!-- <span style="font-size: 20px;">2023年4月9日 - 4月15日</span> -->
          <span style="font-size: 20px">{{ firstDay }} - {{ lasttDay }}</span>
          <i
            class="el-icon-arrow-left"
            style="
              border: 1px solid #ccc;
              padding: 3px;
              margin: 0 16px;
              cursor: pointer;
            "
            @click="lastWeek"
          ></i>
          <i
            class="el-icon-arrow-right"
            style="border: 1px solid #ccc; padding: 3px; cursor: pointer"
            @click="nextWeek"
          ></i>
        </p>
        <p class="contantTitle_Tabs">
          <!-- <button>篮球场</button>
                  <button>网球场</button> -->
          <button
            v-for="(item, index) in tabs"
            :key="index"
            @click="handleSite(index, item)"
            :class="{ action: activeTab === index }"
          >
            {{ item }}
          </button>
        </p>
      </div>
      <!-- 日历 -->
      <div class="contantTable">
        <table v-loading="tableLoading" element-loading-text="loading">
          <thead>
            <tr>
              <th></th>
              <th
                style="text-align: center"
                v-for="(item, index) in md"
                :key="index"
              >
                {{ item }} ({{ week[index] }})
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr v-for="item in siteName" :key="item.id"> -->
            <template v-if="filteredSiteName.length > 0">
              <tr v-for="item in filteredSiteName" :key="item.id">
                <th style="text-align: center; vertical-align: middle">
                  <p class="table-first-col">
                    {{ item.siteName || item.siteType === siteType }}
                  </p>
                </th>
                <td v-for="(i, index) in timeQuantum" :key="index">
                  <div v-for="(ii, index) in i[Yms[index]]" :key="index + 'a'">
                    <p
                      v-for="(iii, index) in ii[item.siteName]"
                      :key="index + 'b'"
                      :class="i.cssType"
                      @click="timeClick(i, ii, iii)"
                    >
                      {{ iii }}
                    </p>
                  </div>
                </td>
              </tr>
            </template>
            <!-- <template v-else>
                          <tr>
                              <td colspan="8" style="text-align: center;">暂无数据</td>
                          </tr>
                      </template> -->
          </tbody>
        </table>
      </div>
    </div>
    <!-- 预定场地 弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="728px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      class="Popup"
    >
      <template slot="title">
        <span>场地预定</span>
      </template>
      <reservation
        :projectList="projectList"
        ref="reservationRef"
      ></reservation>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="addreservation"
          v-loading="submitLoading"
          >确认预约</el-button
        >
      </span>
    </el-dialog>
    <!-- 详情 弹窗 -->
    <el-dialog
      title="预定详情"
      :visible.sync="dialogVisible1"
      width="728px"
      :before-close="handleClose"
      class="Popup"
      :close-on-click-modal="false"
    >
      <detailed :detailsObj="detailsObj" ref="detailed"></detailed>
      <span slot="footer" class="dialog-footer">
        <el-button @click="getRemoveOrderID">取消预定</el-button>
      </span>
    </el-dialog>
    <!-- 取消预定 弹窗 -->
    <el-dialog
      title="取消预定"
      :visible.sync="dialogVisible2"
      width="30%"
      :before-close="handleCloseDialog"
      class="Dialog"
      :close-on-click-modal="false"
    >
      <!-- <p class="dialog-title">*请填写取消原因,取消原因将会发送给用户</p>
          <el-input type="textarea" :rows="4" placeholder="因场地临时休整，已为您取消场地预约，如需重新预约请在锦绣小程序内重新申请" v-model="cancelReason">
          </el-input> -->
      <el-form
        :model="ruleFormDialog"
        :rules="rulesDialog"
        ref="ruleFormDialog"
        label-width="100px"
        class="demo-ruleForm"
        label-position="top"
      >
        <el-form-item
          label="请填写取消原因,取消原因将会发送给用户"
          prop="cancelReason"
        >
          <el-input
            type="textarea"
            :rows="4"
            placeholder=""
            v-model="ruleFormDialog.cancelReason"
            maxlength="20"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <el-checkbox
        v-model="cancelchecked"
        class="dialog-checkbox"
        v-show="cancelShow"
        >费用退回</el-checkbox
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">关 闭</el-button>
        <el-button type="primary" @click="getRemoveOrder">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCalendarList,
  getPlaceList,
  addPredetermine,
  getCalendarDetails,
  removeOrder,
} from "@/api/ruge/lego/stadium";
import { getProjectList } from "@/api/business/base/tenant/map";
import reservation from "./components/reservation";
import detailed from "./components/detailed";
export default {
  name: "calendarComponent",
  components: {
    reservation,
    detailed,
  },
  data() {
    return {
      submitLoading: false,
      projectId: "",
      ruleFormDialog: {
        cancelReason: "",
      },
      rulesDialog: {
        cancelReason: [
          { required: true, message: "请输入取消原因", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
      // 年月日 以及对应的周期
      md: [],
      week: [],
      firstDay: "",
      lasttDay: "",
      siteName: [], //获取场地列表
      timeQuantum: [], // 获取日历时间段列表
      Yms: [], //当前一周的年月日
      tabs: ["篮球场", "网球场"],
      activeTab: 0,
      siteType: "basketball",
      tableLoading: false,
      dayNum: 0,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      detailsObj: {},
      cancelReasonId: "",
      cancelShow: false,
      cancelchecked: false,
      projectList: [],
    };
  },
  created() {
    this.getWeekList();
    this.getTableList();
    this.initProjectList();
  },
  methods: {
    updataTimeQuantum() {
      const today = Date.now();
      const tadayStartTimestamp = new Date().setHours(0, 0, 0, 0);
      const tadayEndTimestamp = new Date().setHours(23, 59, 59, 999);
      // console.log(tadayStartTimestamp, tadayEndTimestamp, '---');
      // console.log(today, 'today');
      this.timeQuantum.forEach((item) => {
        const date = new Date(Object.keys(item)[0].toString()).getTime();
        // console.log(date, 'date');
        if (date > tadayEndTimestamp) {
          // console.log('大');
          // item.isDestined = 'light';
          this.$set(item, "cssType", "light");
        } else if (date < tadayStartTimestamp) {
          // console.log('小');
          // item.isDestined = 'grey';
          this.$set(item, "cssType", "grey");
        } else {
          // console.log('等于');
          // this.$set(item, 'cssType', '');
        }
      });
    },
    initProjectList() {
      getProjectList().then((res) => {
        this.projectList = res || [];
      });
    },
    // 初始化数据
    async getTableList() {
      this.tableLoading = true;
      // 获取时间段
      const params = {
        dates: this.Yms,
        siteType: this.siteType,
      };
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      const result = await getCalendarList(params);
      this.timeQuantum = result;
      this.updataTimeQuantum();
      // 获取场地列表
      const siteList = await getPlaceList({ projectId: this.projectId });
      this.siteName = siteList;
      this.tableLoading = false;
    },
    // 获取日期
    getWeekList() {
      // 获取当前日期
      const today = new Date();
      const week = []; //周
      const md = []; // month + day 月 日
      for (let i = 0; i <= 6; i++) {
        const date = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() -
            (today.getDay() ? today.getDay() : 7) +
            i +
            1 +
            this.dayNum
        );
        md[i] =
          this.fillZero(date.getMonth() + 1) +
          "-" +
          this.fillZero(date.getDate());
        let index = date.getDay();
        week[i] = "周" + "日一二三四五六".charAt(index);
      }
      let result = [];
      result["md"] = md;
      result["week"] = week;
      // console.log(md, 'md');
      // console.log(week, 'week');
      // console.log(result, 'result');
      this.md = result.md;
      this.week = result.week;

      // 获取当前日期是星期几（0 表示星期日，1 表示星期一，依此类推）
      const dayOfWeek = today.getDay() ? today.getDay() : 7;
      // 获取当前日期所在星期的第一天（星期日）
      let firstDayOfWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - dayOfWeek + 1 + this.dayNum
      );
      // console.log(firstDayOfWeek, 'firstDayOfWeekfirstDayOfWeekfirstDayOfWeek');
      // 获取当前日期所在星期的最后一天（星期六）
      const lastDayOfWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + (this.dayNum + 7 - dayOfWeek)
      );
      // 输出当前日期所在星期的年月日
      // console.log(`第一天：${firstDayOfWeek.getFullYear()}-${firstDayOfWeek.getMonth() + 1}-${firstDayOfWeek.getDate()}`)
      // console.log(`最后一天：${lastDayOfWeek.getFullYear()}-${lastDayOfWeek.getMonth() + 1}-${lastDayOfWeek.getDate()}`)
      let firstDay = `${firstDayOfWeek.getFullYear()}年${
        firstDayOfWeek.getMonth() + 1
      }月${firstDayOfWeek.getDate()}日`;
      let lasttDay = `${
        lastDayOfWeek.getMonth() + 1
      }月${lastDayOfWeek.getDate()}日`;
      this.firstDay = firstDay;
      this.lasttDay = lasttDay;
      // 将日期拼接成XXXX-XX-XX 并存起来
      let Yms = result.md.map(
        (item) => `${firstDayOfWeek.getFullYear()}-${item}`
      );
      this.Yms = Yms;
      // this.Yms = ["2023-05-22","2023-05-23","2023-05-24","2023-05-25","2023-05-26","2023-05-27","2023-05-29"]
      // console.log(this.Yms, 'this.Yms');
    },
    fillZero(n) {
      return n < 10 ? "0" + n : "" + n;
    },
    nextWeek() {
      this.dayNum += 7;
      this.getWeekList();
      this.getTableList();
    },
    lastWeek() {
      this.dayNum -= 7;
      this.getWeekList();
      this.getTableList();
    },
    // 场地切换
    handleSite(index, item) {
      item == "篮球场" ? (item = "basketball") : (item = "tennis");
      // console.log(item, 'item');
      this.siteType = item;
      // console.log(this.basketball,'this.basketball');
      this.activeTab = index;
      this.getTableList();
    },
    refresh() {
      // location.reload() // 刷新页面
    },
    // 打开预定场地弹窗
    handleFix() {
      this.dialogVisible = true;
    },
    // 弹窗关闭配置
    handleClose(done) {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      this.cancelShow = false;
      if (this.$refs["reservationRef"]) {
        this.$refs["reservationRef"].$refs.ruleForm.resetFields();
        this.$refs["reservationRef"].ruleForm.destinedDate = "";
        this.$refs["reservationRef"].ruleForm.monthPayCompany = "";
      }
      // this.getTableList();
    },
    handleCloseDialog() {
      this.dialogVisible2 = false;
      this.cancelchecked = false;
      this.$refs.ruleFormDialog.resetFields();
    },
    // 新增场地预定配置
    addreservation() {
      this.$refs["reservationRef"].$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          let ruleList = this.$refs["reservationRef"].ruleForm;
          let ruleFormList = {
            projectId: ruleList.projectId,
            userName: ruleList.userName,
            userPhone: ruleList.userPhone,
            destinedDate: ruleList.destinedDate,
            orderType: 1,
            siteType: ruleList.orderType,
            totalMoney: ruleList.totalMoney,
            monthPayMoney: ruleList.monthPayMoney,
            monthPayCompany: ruleList.monthPayCompany,
            // goodsList: [
            // {
            //     goodsId: ruleList.siteType[0].goodsId,
            //     goodsName: ruleList.siteType[0].siteName,
            //     goodsSlotList: [
            //         {
            //             destinedSlot: ruleList.siteType[1]
            //         }
            //     ]
            // }
            // ]
          };
          // --------------
          // console.log('ruleList.siteType', ruleList.siteType);
          let goodsHeadList = [];
          let goodsList = [];
          // 收集头部
          ruleList.siteType.map((item) => {
            goodsHeadList.push(item[0]);
          });
          // console.log('goodsHeadList', goodsHeadList);
          // 头部去重
          let uniqueGoodsHeadList = [...new Set(goodsHeadList)]; // 去重
          // console.log('uniqueGoodsHeadList', uniqueGoodsHeadList);
          // 创建模板
          uniqueGoodsHeadList.map((item) => {
            goodsList.push({
              goodsId: item.goodsId,
              goodsName: item.siteName,
              goodsSlotList: [],
            });
          });
          // console.log('初始化goodsList', goodsList);

          // 往模板填时间段
          ruleList.siteType.map((item) => {
            goodsList.map((j, jIndex) => {
              if (item[0].goodsId == j.goodsId) {
                goodsList[jIndex].goodsSlotList.push({ destinedSlot: item[1] });
              }
            });
          });
          // console.log('goodsList', goodsList);
          // 赋值ruleFormList.goodsList
          ruleFormList.goodsList = Object.assign([], goodsList);
          // --------------------
          addPredetermine(ruleFormList)
            .then((res) => {
              // console.log(res, '新增场地预定配置');
              // 预定场地 表单重置
              this.$refs["reservationRef"].$refs.ruleForm.resetFields();
              this.$refs["reservationRef"].ruleForm.destinedDate = "";
              this.$refs["reservationRef"].ruleForm.monthPayCompany = "";
              this.getTableList();
              if (res.code == 200) {
                this.$message({
                  message: "预约成功",
                  type: "success",
                });
                this.dialogVisible = false;
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
                this.dialogVisible = true;
              }
            })
            .finally(() => {
              this.submitLoading = false;
            });
        } else {
          console.log("失败");
        }
      });
    },
    // 场地日历详情
    timeClick(i, j, k) {
      let destinedDate = Object.keys(i)[0];
      // console.log(destinedDate,'destinedDate');
      let siteName = Object.keys(j).toString();
      let destinedSlot = k;
      this.dialogVisible1 = true;
      getCalendarDetails({
        destinedDate: destinedDate,
        siteName: siteName,
        destinedSlot: destinedSlot,
      }).then((res) => {
        // console.log(res, 'res');
        this.detailsObj = res;
        this.cancelReasonId = res.id;
        if (res.destinedRoad == "wx") {
          this.cancelShow = true;
        }
      });
    },
    // 取消预定
    getRemoveOrderID() {
      this.dialogVisible2 = true;
    },
    // 取消预约 配置
    getRemoveOrder() {
      this.$refs.ruleFormDialog.validate((valid) => {
        if (valid) {
          removeOrder({
            id: this.cancelReasonId,
            cancelReason: this.ruleFormDialog.cancelReason,
            refund: this.cancelchecked,
          }).then((res) => {
            console.log(res, "取消订单");
            this.dialogVisible1 = false;
            this.dialogVisible2 = false;
            this.cancelShow = false;
            this.getTableList();
            this.$refs.ruleFormDialog.resetFields();
            if (res.code == 200) {
              this.$message({
                message: "取消成功",
                type: "success",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        }
      });
    },
  },
  computed: {
    filteredSiteName() {
      return this.siteName.filter((item) => item.siteType === this.siteType);
    },
  },
};
</script>
<style lang="less" scoped>
.stadium-site-calendar {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    .project-selector {
      width: 200px;
      margin-right: 10px;
    }
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
  .calendar-contant {
    .contantTitle {
      display: flex;
      justify-content: space-between;
      .contantTitle_Tabs {
        width: 160px;
        height: 50px;
        background-color: #f8f8f8;
        border: 5px solid #f8f8f8;
        button {
          cursor: pointer;
          display: inline-block;
          height: 40px;
          width: 50%;
          line-height: 38px;
          text-align: center;
          // color: #909399;
          font-size: 14px;
          border: none;
          background-color: #f8f8f8;
        }
        .action {
          background-color: #fff;
          color: blue;
          border-radius: 5px;
        }
      }
    }
  }
  .contantTable {
    margin-top: 20px;
    table {
      border-collapse: collapse;
      width: 100%;
    }
    th,
    td {
      border: 1px solid #f2f2f2;
      padding: 8px 12px;
      height: 30px;
      line-height: 30px;
    }
    thead > tr:first-child > th:first-child {
      background-color: transparent;
    }
    th:first-child {
      width: 110px;
    }
    td {
      height: 100px;
      text-align: center;
      line-height: 100px;
      p {
        height: 20px;
        background-color: #1a4cec;
        color: #ffffff;
        border-radius: 3px;
        line-height: 20px;
        margin: 5px 0;
        cursor: pointer;
      }
    }
    th {
      background-color: #f5f5f5;
      text-align: left;
    }
  }
  .Dialog {
    .dialog-title {
      font-size: 12px;
      color: gray;
      margin-bottom: 5px;
    }

    .dialog-checkbox {
      color: gray;
      margin-top: 5px;
    }
  }
}
.light {
  background-color: #f4f6fe !important;
  color: #1a4cec !important;
}
.grey {
  background-color: #f8f8f8 !important;
  color: #909399 !important;
}
.Popup {
  // 弹窗头部和底部固定
  :deep(.el-dialog) {
    height: 100%;

    .el-dialog__body {
      max-height: calc(100% - 154px);
      overflow-y: auto;
    }
  }
}
</style>